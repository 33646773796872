import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { TabsBasic, TabsSmall, TabsWithContent, TabsWithIcons } from "../../examples/components/Tabs";
export const query = graphql`
  query TabsQuery {
    Tabs: componentMetadata(displayName: { eq: "Tabs" }) {
      ...ComponentApi_metadata
    }
    Tab: componentMetadata(displayName: { eq: "Tab" }) {
      ...ComponentApi_metadata
    }
    TabContainer: componentMetadata(displayName: { eq: "TabContainer" }) {
      ...ComponentApi_metadata
    }
    TabContent: componentMetadata(displayName: { eq: "TabContent" }) {
      ...ComponentApi_metadata
    }
    TabPane: componentMetadata(displayName: { eq: "TabPane" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Create dynamic tabbed interfaces, as described in the{" "}
  <a href="https://www.w3.org/TR/wai-aria-practices/#tabpanel">
    <abbr title="Web Accessibility Initiative">WAI</abbr>{" "}
    <abbr title="Accessible Rich Internet Applications">ARIA</abbr> Authoring
    Practices
  </a>
  . <code>Tabs</code> is a higher-level component for quickly creating a <code>
    Nav
  </code> matched with a set of <code>TabPane</code>s.
    </Overview>
    <CodeBlock title="Basic Tabs" code={TabsBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Tabs with Icons" code={TabsWithIcons} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Small Tabs" code={TabsSmall} mdxType="CodeBlock">
  To use smaller variants of tabs, add the <code>.nav-tabs-sm</code> class.
    </CodeBlock>
    <CodeBlock title="Tabs with Content" code={TabsWithContent} mdxType="CodeBlock">
  No additional code is required to handle tabs with content.
    </CodeBlock>
    <LinkedHeading h="2" id="tabs-api" className="h1" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Tabs} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.Tab} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.TabContainer} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.TabContent} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.TabPane} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      